.projectsContainer {
	width: 100%;
	padding-bottom: 5vh;	
	display: flex;
  flex-flow: column;
  justify-content: space-between;
  z-index: 100;	
	padding-bottom: vh;

 -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
        animation: fadein 1s;
  }

.Bg-white .App-link {
	color: #00f;
}

.projectsContainer .Top {
  visibility: hidden;
  flex: none;
}

/*------------------------ projectModule ------------------------ */

.projectModule {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  }

.projectModuleReverse {
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-between;
          align-items: flex-end;
  }

.projectModule:hover * {
	visibility: visible;
	opacity: 1;
	-webkit-transition: 0.1s;
	transition: 0.1s;
}

.projectModuleReverse:hover * {
	visibility: visible;
	opacity: 1;
	-webkit-transition: 0.1s;
	transition: 0.1s;
}

.projectModule img {
  width: 50vw;
  opacity: 0.75;
}

.projectModuleReverse img {
  width: 50vw;
  opacity: 0.75;
}

.asideProjectInfo {
  padding: 0 4vh 4vh 4vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-end;
  visibility: hidden;

}

.asideProjectDetails { 
  position: relative;
  font-family: 'Falster';
  font-size: 1.5rem;
  /*border: blue 1px solid;*/
}

.asideProjectTitle { 
  position: relative;
  font-family: 'Falster';
  font-size: 3rem;
  text-transform: uppercase;  
}

.asideProjectTitle a {
  text-decoration: none;
}

/*---------------------------animations---------------------------*/

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

/*---------------------------MOBILE---------------------------*/

@media all and (max-width: 800px) {

 	.projectModule, .projectModuleReverse {
 		text-align: center;
 	}

	.projectModule img {
		opacity: 1;
		width: 90vw;
		margin: 0 2vh 0 2vh ;
	}

	.projectModuleReverse img {
		opacity: 1;
		width: 90vw;
		margin: 0 2vh 0 2vh ;
	}

	.mobileProjectTitle { 
		font-family: 'Falster';
		font-size: 2rem;
		text-transform: uppercase;
		visibility: visible;
		margin: 2vh 2vh 2vh 2vh;
	  /*border: blue 1px solid;*/
	}

	.projectsContainer .mobileHeader {
		color: #00f;
	}

	.projectsContainer .mobileNavContainer {
		color: #00f;
	}

	.projectsContainer .App-link {
	text-decoration: none;
	}


/*---------------------------hidden elements in mobile---------------------------*/

	.asideProjectInfo, .backArrowFixed, .projectModuleInfo, .Top {
		display: none;
	}

	.aboutBody {
		font-size: 6vw;
		margin: 0 2vh 0 2vh;
	}

	.aboutImage {
		display: none;
		position: relative;
	}
}

/*---------------------------DESKTOP---------------------------*/

@media all and (min-width: 800px) {
	.mobileProjectTitle, .mobileNavContainer, .mobileHeader, .mobileTitleLeft, .mobileTitleRight, .mobileTitleCenter {
		display: none;
	}
}

/*---------------------------VERY LARGE---------------------------*/

@media all and (min-width: 1600px) {

.projectModule {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}

.projectModuleReverse {
  display: flex;
  flex-flow: row-reverse nowrap;
  justify-content: space-around;
}

	.projectLeft img {
	  width: 1000px;
}

	.projectRight img {
	  width: 1000px;
}
}
