.App {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
            padding-bottom: 10vh;
}

/* ipAddress */

.ipAddress .App-link {
  text-decoration: none;
}

/* footer */

.footerContainer {
  font-family: 'Falster';
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: static;
  align-self: flex-end;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
  justify-content: space-around;
  text-align: center;
  padding: 4vh 0 5vh 0;
  width: 100vw;
  bottom: 5vh;
  z-index: 20;

}

.footerContainer .App-link {
  text-decoration: none;
}

.footerLeft {
  padding: 0 0 1vh 0;
}

.footerRight {
  padding: 0 0 1vh 0;

}


/* ------------------------------------  ARROWS ------------------------------------   */


.arrowW, .arrowE, .arrowN, .arrowS, .arrowSE, .arrowSW, .arrowNE, .arrowNW {
    text-decoration: none;
    display: inline;
  }

.arrowW:after { content: "\2190"; }
.arrowE:after { content: "\2192"; }
.arrowN:after { content: "\2191"; }
.arrowS:after { content: "\2193"; }
.arrowSE:after { content: "\2198"; }
.arrowSW:after { content: "\2199"; }
.arrowNE:after { content: "\2197"; }
.arrowNW:after { content: "\2196"; }

.backArrow {
  font-size: 3rem;
  text-align: left;
  margin: 0 0 -1vh 8vh;
  text-decoration: none;
  color: #fff;
}

.backArrow:after {
  content: "\2190";
}

.backArrowFixed:after {
  content: "\2190";
}

.backArrowFixed {
  font-size: 3rem;
  text-align: left;
  top: 6.4vh;
  margin: 0 0 0 8vh;
  text-decoration: none;
  color: #fff;
  position: fixed;
}

/* ------------------------------------  MOBILE ------------------------------------   */


@media all and (max-width: 800px) {

.App > * {
      -ms-flex: none;
          flex: none;
  }
}

@media all and (min-width: 800px) {

}
