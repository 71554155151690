body {
  overflow: hidden;
}

.Center {
  text-align: center;
  z-index: 0;
  align-self: flex-end;
  z-index: 3;

}


.centerText {
  display: inline;
  font-family: "Adieu-Regular",'Helvetica', sans-serif;
  font-size: 17vh;
  line-height: 0.9;
  text-align: center;
   /*border: 1px solid; */
}

.centerText a {
  text-decoration: none;
  color: #fff;
  cursor:  url('../assets/cursor-click.png'), auto;

}

.centerText:hover {
  -webkit-filter: blur(5px) grayscale(5%);
          filter: blur(5px) grayscale(5%);
  cursor:  url('../assets/cursor-click.png'), auto;
}

.Top, .Right, .Left, .Bottom {
  visibility: visible;
  line-height: 1;
  margin: 2vh;
  flex: 1 1 100%;
  z-index: 10;
}

.Bottom {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: flex-end;
  text-align: center;
}

.hidden {
  -webkit-transition: 7s;
  transition: 7s;
  opacity: 0;
  visibility: hidden;
}

/* ------------------------------------  WIDGETS ------------------------------------   */

/* the two home display projects, .projectHome and .projectHome */

.projectHome {
  position: absolute;
  left: 15vh;
  top: 53vh;
  z-index: 10;
}

.projectHomeOffset {
  position: absolute;
  left: 35vh;
  top: 58vh;
  z-index: 10;
}

.projectHomeOffset img, .projectHome img {
  width: 30vw;
  opacity: 0.7;
  z-index: 10;
}

.homeProjectModuleText {
 font-family: 'Falster';
 font-size: 7vh;
 width: 100%;
 color: #fff;
 bottom: 30%;
 right: 10%;
 position: absolute;
 visibility: hidden;
 line-height: 0.9;
 text-shadow: 2px 2px 3px #00f;
 zIndex: 10;
 /*border: red 1px solid;*/
}

.projectHome:hover .homeProjectModuleText {
  visibility: visible;
}

.projectHomeOffset:hover .homeProjectModuleText {
  visibility: visible;
}

.homeProjectModuleText .App-link {
  text-decoration: none;
}

.projectHomeOffset:hover img, .projectHome:hover img {
  opacity: 1;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  }

/* Draggable circlular text widget */

.circleText {
  left: 80vw;
  top: -10vh;  
  font-family: 'Falster';
  font-size: 1rem;
  position: absolute;
  -webkit-animation: rotation 14s infinite linear;
          animation: rotation 14s infinite linear;
}

/* Widget positions */

.Widget:hover {
  -webkit-filter: blur(2px) /*hue-rotate(-70deg) contrast(250%)*/;
          filter: blur(2px) /*hue-rotate(-70deg) contrast(250%)*/;
  cursor:  url('../assets/cursor-click.png'), auto;
}

.widgetPositionOne {
  position: absolute;
  right: 5vw;
  top: 5vh; 
  z-index: 10;
}

.widgetPositionOne > img {
  width: 70vh;
}

.widgetPositionTwo {
  position: absolute;
  right: 0vw;
  bottom: 0vh;
  z-index: 1;
}

.widgetPositionThree {
  position: absolute;  
  right: 10vw;
  bottom: 45vh;
  z-index: 1;
}

/*---------------------------animation---------------------------*/
@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */

/*---------------------------MOBILE---------------------------*/

@media all and (max-width: 800px) {

   .Top, .Center, .Blurb, .widgetPositionOne, .widgetPositionTwo, .widgetPositionFour, .projectHome, .projectHomeOffset {
    display: none;
  }

.footerContainer {
  font-family: 'Falster';
  display: flex;
  position: static;
  flex-flow: column nowrap;
          justify-content: space-between;
          padding: 2vh 0 15vh 0;
        }

  .widgetPositionThree {
    bottom: 25vh;
    left: 10vw;
    z-index: 0;
    }

  .App {
  display: flex;
  flex-flow: column;
  text-align: left;
  justify-content: flex-start;
  }

  .hidden {
    visibility: visible;
    opacity: 1;
  }

 .Right, .Bottom, .Left, .Hello {
    font-size: 6vw;
    margin: 0 2vh 2vh 2vh;
    text-align: left;
    flex: none;
    z-index: 1;
  }  

  .Bottom {
    display: block;
  }

  .Bottom .App-link {
    color: #FF696B;
    text-decoration: underline;
  }

  .App-link {
  color: #FF696B;
  text-decoration: underline;
  }

/*---------------------------Mobile Navbar---------------------------*/

  .mobileHeader {
    color: #fff;
    text-align: center;
    font-size: 15vw;
    line-height: 1;
    margin: 3vh 2vh 2vh 0;
    font-family: "Adieu-Regular";
    z-index: 5;
  }

  .mobileNavContainer {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 1vh 1vh 0;
    font-family: "Falster";
    z-index: 5;
  }

  .mobileTitleLeft, .mobileTitleCenter, .mobileTitleRight {
      font-size: 10vw;
      line-height: 1.2;
      margin: 0 0 0 2.2vh;    
  }

  .mobileTitleLeft: {
    text-align: left;
  }

  .mobileTitleRight {
    text-align: right;
  }

  .mobileTitleCenter {
    text-align: center;
  } 

  .mobileNavContainer .App-link {
    text-decoration: none;
  }
  
  .Orange {
    color: #FF696B;
    text-decoration: underline;
  }

  .Arrow {
    text-decoration: none;
  }

}


/*---------------------------Desktop---------------------------*/


@media all and (min-width: 800px) {

  .Hello, .Right, .Bottom, .Left, .mobileNavContainer, .mobileHeader {
    display: none;
  }

 .Center + .Top {
  z-index: 0;
 }

  .aside {
    flex:  1 0;
  }

  .Left {
    order: 1;
    -webkit-writing-mode: tb-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: tb-rl;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }

  .Right {
    order: 4;
    -webkit-writing-mode: tb-rl;
        -ms-writing-mode: tb-rl;
            writing-mode: tb-rl;
  }

  .Bottom  { 
    order: 5;
}

  .Center { flex: 3 1; }

  .Center { order: 2; }

  .Blurb { order: 3; }

  .footerContainer { order: 4; }

    
}