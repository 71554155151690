.App {
  height: 100vh;
  width: 100vw;
  overflow: auto;
  margin: 0;
  padding: 0;
  cursor:  url('./assets/cursor-default.png'), auto;
}

.Bg-white {
  background-color: #fff;
  color: #00f;
}

.Bg-blue {
  background-color: #00f;
  color: #fff;
}

.App-link {
  color: #fff;
}

.App-link:hover {
  color: #FF696B;
  -webkit-transition: 0.1s;
  -o-transition: 0.1s;
  transition: 0.1s;
  cursor:  url('./assets/cursor-click.png'), auto;
}
