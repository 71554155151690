.projectPageContainer {
    display: flex;
    flex-direction: column;
    padding-bottom: 10vh;
   -webkit-animation: fadein 0.5s; /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
        animation: fadein 0.5s;
  }

.projectPageContainer .Top {
  visibility: hidden;
  flex: none;
}

.projectPage {
  display: flex;
  width: 100vw;
  flex: 1 1 100%;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
}

.projectBody {
  text-align: left;
  font-family: 'Falster', 'Helvetica', sans-serif;
  font-size: 2rem;
}

.Recto .projectBody {
  margin: 4vh 6vh 4vh 4vh;

}

.Verso .projectBody {
  margin: 4vh 8vh 4vh 4vh;

}

.headerMargin {
  margin: 0 4vh 4vh 4vh;
}

.headerDetails {
  margin: 4vh 0 0 0;
}

.projectPageContainer .Top {
  flex: none;
}

.projectTitle {
  font-family: 'Adieu-Regular', 'Helvetica', sans-serif;
  text-transform: uppercase;
  font-size: 4rem;
  -webkit-hyphens: auto;
      -ms-hyphens: auto;
          hyphens: auto;
}

.projectTag {
  font-family: 'Falster', 'Helvetica', sans-serif;
  font-size: 4rem;
}

.projectLocation, .projectEvent, .projectLink {
  font-family: 'Falster', 'Helvetica', sans-serif;
  font-size: 2rem;
  margin: 0 2vh 0 2vh;
}

.Verso .projectImage {
  width: 50vw;
  margin: 2vh 2vh 0 0vh;
}

.Recto .projectImage {
  width: 48vw;
  margin: 2vh 0 0 2vh;
}

.projectImageCaption {
}


/*---------------------------MOBILE---------------------------*/

@media all and (max-width: 800px) {

.projectPage {
  display: flex;
  width: 100vw;
  flex: 1 1 100%;
  flex-flow: column wrap;
  justify-content: space-between;
  align-items: flex-start;
  flex-grow: 1;
}

.Verso {
  width: 100vw;
}

.Recto {
  width: 100vw;
}

.projectBody {
  text-align: left;
  font-family: 'Falster', 'Helvetica', sans-serif;
  font-size: 1.5rem
}

.Recto .projectBody {
  margin: 4vh 6vw 0 6vw;
}

.headerMargin {
  margin: 0 4vh 2vh 2vh;
}

.headerDetails {
  margin: 2vh 0 0 0;
}

.projectTitle {
  font-family: 'Adieu-Regular', 'Helvetica', sans-serif;
  text-transform: uppercase;
  font-size: 2.5rem;
  margin: 2vh 0 0 2vh;
}

.projectTag {
  font-family: 'Falster', 'Helvetica', sans-serif;
  font-size: 2.5rem;
}

.projectLocation, .projectEvent, .projectLink {
  font-family: 'Falster', 'Helvetica', sans-serif;
  font-size: 1.5rem;
  margin: 0 0 0 2vh;
}

.Verso .imgWrapper {
  display: none;
}

.Recto .projectImage {
  width: 90vw;
  margin: 0 0 0 0;
  text-align: right;
}

.projectImageCaption {
}

.projectPageContainer .App-link {
  text-decoration: none;
}

.mobileBackArrow {
  margin: 0 0 0 -2vh;
  text-decoration: none;
  color: #FF696B;
}

  /*---------------------------Mobile Navbar---------------------------*/

  .Orange {
    color: #FF696B;
    text-decoration: underline;
  }

  .Arrow {
    text-decoration: none;
  }

/*hidden elements in mobile*/
  .Top, .circleText, .backArrow {
    display: none;
  }
}

/*---------------------------DESKTOP---------------------------*/

@media all and (min-width: 800px) {
  .mobileBackArrow, .lowerLineTitle, .mobileNavContainer, .mobileHeader {
    display: none;
  }

  .Top {
    display: block;
  }
}